import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";

import { navigate } from "gatsby";
import { useConfig } from "../../hooks/useConfig";
import { GtmEven, usePushGtmEvent } from "../../hooks/useGmtPushEvent";
import Button from "../form/Button";
import Section from "../layout/Section";
import BodyText from "../typography/BodyText";
import TitleTag from "../typography/TitleTag";
import { ChevronRightIcon } from "@heroicons/react/solid";

// @ts-ignore
import Carlo from "../../assets/images/sales-rappresentative.jpg";

const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
  privacy1: false,
  privacy2: false,
};

const ContactUs = () => {
  const [formData, updateFormData] = React.useState(initialFormData);
  const [sending, setSending] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const { contactUs } = useConfig();
  const { t } = useTranslation();
  const pushGtmEvent = usePushGtmEvent();

  const pathname =
    typeof window !== "undefined" ? window.location.pathname : "";
  const callUrl = new URL(contactUs.callUrl);
  callUrl.searchParams.append("utm_content", pathname);

  const onFormSubmit = async (event) => {
    event.preventDefault();
    setSending(true);
    setShowError(false);

    try {
      await fetch(contactUs.endpoint, {
        method: "POST",
        body: JSON.stringify(formData),
      });
      navigate("/contact/thanks");
      pushGtmEvent({ event: GtmEven.contactFormSubmit });
    } catch (e) {
      console.error(e);
      setShowError(true);
      setSending(false);
    }
  };

  const handleFormChange = (event) => {
    const val =
      event.target.name === "privacy1" || event.target.name === "privacy2"
        ? event.target.checked
        : event.target.value.trim();

    updateFormData({
      ...formData,
      [event.target.name]: val,
    });
  };

  return (
    <Section id="contact-form" className="border-t pt-24 flex flex-wrap">
      <div className="mt-0.5 lg:w-1/2 lg:pr-16">
        <div className="w-full mb-6">
          <TitleTag>{t("contactUsSection.contactUs")}</TitleTag>
          <h4 className="text-2xl leading-snug mb-3">
            <span className="text-gray-900 font-extrabold tracking-tight">
              {t("contactUsSection.title")}
            </span>
            <span className="text-ol-gradient font-extrabold">
              {" "}
              {t("contactUsSection.subTitle")}
            </span>
          </h4>
          <BodyText>{t("contactUsSection.content")}</BodyText>
          <a
            className="py-4 space-x-2 flex items-center justify-between"
            href={callUrl.toString()}
            target="_blank"
          >
            <div className="flex items-center space-x-3 sm:space-x-6">
              <img
                src={Carlo}
                className="w-16 md:w-20 h-24 rounded-md object-cover"
              />
              <div>
                <span className="text-primary tracking-widest text-xs font-extrabold uppercase">
                  {t("contactUsSection.call.tag")}
                </span>
                <p className="text-gray-900 font-extrabold leading-snug text-xl">
                  {t("contactUsSection.call.title")}
                </p>
                <p>Carlo Vassallo</p>
              </div>
            </div>
            <div className="h-24 justify-self-end flex items-center justify-center px-1 sm:px-3 py-3 text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200">
              <ChevronRightIcon className="inline h-8 w-8 -mt-[1px] ml-1" />
            </div>
          </a>
        </div>
      </div>
      <div className="lg:w-1/2 w-full sm:mt-0">
        <form onSubmit={onFormSubmit}>
          <div className="flex flex-wrap lg:pr-7">
            <div className="py-2 w-1/2 pr-2">
              <div className="relative">
                <label
                  htmlFor="firstName"
                  className="block text-sm text-gray-600"
                >
                  {t("contactUsSection.firstName")}
                  <span className="ml-1 text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  required
                  onChange={handleFormChange}
                  disabled={sending}
                  className="w-full shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mt-1 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="py-2 w-1/2 pl-2">
              <div className="relative">
                <label
                  htmlFor="lastName"
                  className="block text-sm text-gray-600"
                >
                  {t("contactUsSection.lastName")}
                  <span className="ml-1 text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  required
                  onChange={handleFormChange}
                  disabled={sending}
                  className="w-full shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mt-1 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="py-2 w-full">
              <div className="relative">
                <label htmlFor="email" className="block text-sm text-gray-600">
                  {t("contactUsSection.email")}
                  <span className="ml-1 text-red-600">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={handleFormChange}
                  disabled={sending}
                  className="w-full shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mt-1 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="py-2 w-full">
              <div className="relative">
                <label
                  htmlFor="message"
                  className="block text-sm text-gray-600"
                >
                  {t("contactUsSection.message")}
                </label>
                <textarea
                  id="message"
                  name="message"
                  onChange={handleFormChange}
                  disabled={sending}
                  className="w-full shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 mt-1 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
            </div>
            <div className="py-2 w-full">
              <Link to="/privacy/user-policy">
                <label className="block text-sm text-indigo-500 cursor-pointer underline">
                  {t("join:joinForm.privacy.label")}
                </label>
              </Link>
              <div className="flex space-x-2 items-center mt-2">
                <input
                  type="checkbox"
                  name="privacy1"
                  onChange={handleFormChange}
                  required
                  className="cursor-pointer h-6 w-6 shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-indigo-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                <label className="text-xs text-gray-500">
                  {t("contactUsSection.privacy.check1")}
                </label>
              </div>
              <div className="flex space-x-2 items-center mt-2">
                <input
                  type="checkbox"
                  name="privacy2"
                  onChange={handleFormChange}
                  required
                  className="cursor-pointer h-6 w-6 shadow-sm rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-indigo-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                <label className=" text-xs text-gray-500">
                  {t("contactUsSection.privacy.check2")}
                </label>
              </div>
            </div>
            <div className="py-2 w-full">
              {showError && (
                <div className="w-full mb-4 py-3 px-5 bg-red-50 rounded-md text-red-700">
                  <span className="font-bold">
                    {t("contactUsSection.error.title")}
                  </span>{" "}
                  {t("contactUsSection.error.message")}
                </div>
              )}
              <Button
                type="submit"
                loading={sending}
                disabled={sending}
                text={t("contactUsSection.send")}
                loadingText={t("contactUsSection.sending")}
              />
            </div>
          </div>
        </form>
      </div>
    </Section>
  );
};

export default ContactUs;
