import React, { FunctionComponent } from "react";

type TitleTagProps = {
  className?: string;
};

const TitleTag: FunctionComponent<TitleTagProps> = ({
  children,
  className,
}) => {
  return (
    <h3
      className={`text-primary tracking-widest text-lg font-extrabold uppercase ${className}`}
    >
      {children}
    </h3>
  );
};

export default TitleTag;
