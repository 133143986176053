import React, { FunctionComponent } from "react";

type BodyTextProps = {
  className?: string;
  dark?: boolean;
  justify?: boolean;
  center?: boolean;
  mb?: number | string;
  style?: React.CSSProperties;
  snug?: boolean;
};

const BodyText: FunctionComponent<BodyTextProps> = ({
  children,
  className,
  dark = false,
  justify = true,
  center = false,
  mb = 4,
  snug = false,
}) => {
  return (
    <p
      className={`${dark ? "text-gray-300" : "text-gray-600"} mb-${mb} ${
        justify ? "text-justify" : center ? "text-center" : ""
      } ${className || ""} ${snug ? "leading-snug" : "leading-relaxed"}`}
    >
      {children}
    </p>
  );
};

export default BodyText;
