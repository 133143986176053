import React, { FunctionComponent } from "react";

type ButtonProps = {
  text: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
};

const Button: FunctionComponent<ButtonProps> = ({
  text,
  type,
  disabled,
  loading,
  loadingText = "Loading...",
}) => {
  return (
    <button
      type={type}
      className="cursor-pointer disabled:cursor-default ml-auto w-full md:w-32 flex items-center justify-center py-3 text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 hover:disabled:bg-indigo-600"
      disabled={disabled}
    >
      {loading ? <span className="animate-pulse">{loadingText}</span> : text}
    </button>
  );
};

export default Button;
