import { useEffect } from "react";
import useScript from "./useScript";
// import * as config from "config";
import { useI18next } from "gatsby-plugin-react-i18next";
import { GtmEven, usePushGtmEvent, useGtag } from "./useGmtPushEvent";
import { useConfig } from "./useConfig";

declare var window;

export const useCookieBanner = () => {
  const { language } = useI18next();
  const pushGtmEvent = usePushGtmEvent();
  const gtag = useGtag();
  const { iubenda } = useConfig();

  useEffect(() => {
    if (window._iub?.csConfiguration) return;

    if (!window._iub) window._iub = [];

    window._iub.csConfiguration = {
      ccpaAcknowledgeOnDisplay: true,
      lang: language,
      siteId: iubenda.siteId,
      enableCcpa: true,
      consentOnContinuedBrowsing: false,
      perPurposeConsent: true,
      ccpaApplies: true,
      gdprAppliesGlobally: false,
      cookiePolicyId: iubenda.privacyPolicyId[language],
      invalidateConsentWithoutLog: true,
      banner: {
        listPurposes: true,
        explicitWithdrawal: true,
        brandBackgroundColor: "white",

        acceptButtonDisplay: true,
        acceptButtonColor: "#4b5564",
        acceptButtonCaptionColor: "white",

        customizeButtonDisplay: true,
        customizeButtonColor: "#f3f4f6",
        customizeButtonCaptionColor: "#6b7280",

        rejectButtonDisplay: true,
        rejectButtonColor: "#f3f4f6",
        rejectButtonCaptionColor: "#6b7280",

        position: "float-bottom-right",
        textColor: "#6b7280",
        backgroundColor: "white",
      },
      callback: {
        onPreferenceExpressedOrNotNeeded: function (preference) {
          if (preference) {
            if (
              preference.consent === false ||
              window._iub.cs.api.isCcpaOptedOut()
            ) {
              gtag("consent", "update", {
                functionality_storage: "denied",
                personalization_storage: "denied",
                ad_storage: "denied",
                analytics_storage: "denied",
                custom: true,
              });
              pushGtmEvent({
                event: GtmEven.iubendaPreferenceUpdated,
                source: "iubenda_consent_rejected",
              });
            } else if (preference.consent === true) {
              gtag("consent", "update", {
                functionality_storage: "granted",
                personalization_storage: "granted",
                ad_storage: "granted",
                analytics_storage: "granted",
                custom: true,
              });
              pushGtmEvent({
                event: GtmEven.iubendaPreferenceUpdated,
                source: "iubenda_consent_given",
              });
            } else if (preference.purposes) {
              gtag("consent", "update", {
                functionality_storage:
                  preference.purposes[2] === true ? "granted" : "denied",
                personalization_storage:
                  preference.purposes[3] === true ? "granted" : "denied",
                analytics_storage:
                  preference.purposes[4] === true ? "granted" : "denied",
                ad_storage:
                  preference.purposes[5] === true ? "granted" : "denied",
                custom: true,
              });
              pushGtmEvent({
                event: GtmEven.iubendaPreferenceUpdated,
                source: "iubenda_consent_given_preference",
              });
            }
          }
        },
      },
    };
  }, []);

  useScript({
    src: "//cdn.iubenda.com/cs/ccpa/stub.js",
    async: false,
    charset: "UTF-8",
  });

  useScript({
    src: "//cdn.iubenda.com/cs/iubenda_cs.js",
    async: true,
    charset: "UTF-8",
  });
};

export const useOpenCookieBanner = () => () =>
  window._iub.cs.api.openPreferences();
