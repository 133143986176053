import { useEffect } from "react";

const useScript = ({
  src: url,
  async,
  charset,
}: {
  src: string;
  async?: boolean;
  charset?: string;
}) => {
  useEffect(() => {
    if (document.querySelector(`script[src="${url}"`)) return;

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = url;
    script.async = async;
    script.charset = charset;

    document.body.appendChild(script);
  }, []);
};

export default useScript;
